import AppLoading from "@/components/AppLoading";
import useApi from "@/hooks/useApi";
import useTeamListItems from "@/hooks/useTeamListItems";
import React, { useEffect, useMemo, useState } from "react";
import { IvrIntegrationModel } from "../types";
import SmsIntegrationEditModal from "./SmsIntegrationEditModal";
import SmsIntegrationSetupModal from "./SmsIntegrationSetupModal";
import IvrIntegrationSetupModal from "./IvrIntegrationSetupModal";
import IvrIntegrationEditModal from "./IvrIntegrationEditModal";

export default function IvrIntegration() {
  const [loading, setLoading] = useState(true);
  const [showSetupModal, setShowSetupModal] = useState(false);
  const [integrations, setIntegrations] = useState<IvrIntegrationModel[]>([]);
  const [editedIntegrationId, setEditedIntegrationId] = useState<number>();
  const [editError, setEditError] = useState("");
  const api = useApi();

  const fetchIntegrations = React.useCallback(async () => {
    const response = await api.messaging.get("Sms/Integrations");
    const data: IvrIntegrationModel[] = await response.json();
    setIntegrations(data.filter((integration) => integration.isIvr));
    setLoading(false);
  }, [api.messaging]);

  useEffect(() => {
    fetchIntegrations();
  }, [fetchIntegrations]);

  const { getTeamNameById } = useTeamListItems();

  const errorMap: Record<string, string> = {
    release_error: "An error occured while releasing the phone number.",
    update_error: "An error occured while updating the phone number."
  };

  const editedIntegration = useMemo(() => {
    return integrations.find((x) => x.id === editedIntegrationId);
  }, [editedIntegrationId, integrations]);

  if (loading) {
    return <AppLoading />;
  }

  function editIntegration(id: number) {
    setEditedIntegrationId(id);
  }

  async function release() {
    setLoading(true);
    if (confirm("Are you sure you want to remove this integration?")) {
      try {
        setIntegrations(
          integrations.filter((i) => i.id !== editedIntegrationId)
        );
        const resp = await api.messaging.delete(
          `Sms/Integrations/${editedIntegrationId}`
        );
        if (resp.ok) {
          setEditedIntegrationId(undefined);
          setEditError("");
        }
      } catch (e) {
        console.log(e);
        setEditError(errorMap["release_error"]);
      } finally {
        setLoading(false);
      }
    }
  }

  async function update(integration: IvrIntegrationModel) {
    setLoading(true);
    try {
      const newIntegrations = [...integrations];
      const index = integrations.findIndex((x) => x.id === editedIntegrationId);
      newIntegrations[index] = integration;
      setIntegrations(newIntegrations);

      const resp = await api.messaging.post(
        "Sms/Integrations/UpdatePhoneNumber",
        {
          Id: editedIntegrationId,
          TeamId: integration.teamId
        }
      );
      if (resp.ok) {
        setEditedIntegrationId(undefined);
        setEditError("");
      } else {
        setEditError(errorMap["update_error"]);
      }
    } catch (e) {
      console.log(e);
      setEditError(errorMap["update_error"]);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className="my-5">
        <table className="w-1/2 bg-white">
          <thead>
            <tr>
              <th className="border p-2 text-left">Phone Number</th>
              <th className="border p-2 text-left">Team</th>
              <th className="border p-2 text-left" style={{ width: 1 }}></th>
            </tr>
          </thead>
          <tbody>
            {integrations.map((integration) => (
              <tr key={integration.id}>
                <td className="border p-2">{integration.phoneNumber}</td>
                <td className="border p-2">
                  {getTeamNameById(Number(integration.teamId))}
                </td>
                <td className="border p-2 whitespace-nowrap text-sm">
                  <button
                    className="underline"
                    onClick={() => {
                      editIntegration(integration.id);
                    }}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <button className="btn-blue" onClick={() => setShowSetupModal(true)}>
          Add Phone Number
        </button>
      </div>

      {showSetupModal && (
        <IvrIntegrationSetupModal
          onSuccess={() => {
            setShowSetupModal(false);
            fetchIntegrations();
          }}
          onClose={() => setShowSetupModal(false)}
        />
      )}
      {editedIntegration && (
        <IvrIntegrationEditModal
          onClose={() => {
            setEditedIntegrationId(undefined);
            setEditError("");
          }}
          release={release}
          update={update}
          integration={editedIntegration}
          error={editError}
        />
      )}
    </>
  );
}
