import * as React from "react";
import { FormGroup } from "../RightAsideHelpers";
import Select from "@/components/Select";
import { useContext, useMemo } from "react";
import { WorkflowContext } from "@/context/WorkflowContext";
import { WorkflowNode } from "../../types";

interface Props {
  node: WorkflowNode;
  onUpdate: (node: WorkflowNode) => void;
}

export default function JumpToEditor({ node, onUpdate }: Props) {
  const { workflow } = useContext(WorkflowContext);

  const jumpToOptions = useMemo(() => {
    if (!workflow) {
      return {};
    }
    const result: Record<number, string> = {};

    Object.values(workflow.nodes).forEach((node) => {
      if (node.type !== "action") {
        return;
      }

      if (!node.name) {
        return;
      }

      result[node.id] = node.name;
    });

    return result;
  }, [workflow]);

  return (
    <>
      <FormGroup label="Jump to">
        <Select
          className="w-full border rounded-md p-2"
          options={jumpToOptions}
          value={node.jumpToNodeId}
          onChange={(value) => {
            onUpdate({ ...node, jumpToNodeId: value });
          }}
        />
      </FormGroup>
    </>
  );
}
