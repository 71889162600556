import * as React from "react";
import { WorkflowNode, Question, questionDefs } from "../../types";
import { Divider, FormGroup, ToggleGroup } from "../RightAsideHelpers";
import Input from "@/components/Input";
import IconPlus from "@/icons/IconPlus";
import Select from "@/components/Select";
import { useMemo } from "react";
import IconClose from "@/icons/IconClose";
import TextArea from "@/components/TextArea";

interface Props {
  node: WorkflowNode;
  onUpdate: (node: WorkflowNode) => void;
}

export default function AskQuestionEditor({ node, onUpdate }: Props) {
  const options = node.options || [];

  const questionOptions = useMemo(() => {
    const result: Record<string, string> = {};
    for (const key of Object.keys(questionDefs) as Question[]) {
      result[key] = questionDefs[key].label;
    }
    return result;
  }, []);

  function addOption() {
    //if this is the first option, check to see if this node had a child,
    //it needs to become the child of the first option so that it doesn't get lost.
    const optionChild = options.length === 0 ? node.child : undefined;

    onUpdate({
      ...node,
      options: [...options, { label: "", childNodeId: optionChild }]
    });
  }

  function removeOption(index: number) {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    onUpdate({ ...node, options: newOptions });
  }

  function toggleInvalidReplyAction(
    invalidReplyAction: "repeat" | "failBranch"
  ) {
    onUpdate({ ...node, invalidReplyAction });
  }

  return (
    <>
      <FormGroup label="Question Text">
        <TextArea
          value={node.question || ""}
          onChange={(e) =>
            onUpdate({ ...node, question: e.currentTarget.value })
          }
        />
        {/* <Input
          value={node.question}
          onChange={(question) => onUpdate({ ...node, question })}
        /> */}
      </FormGroup>
      <FormGroup label="Question Type">
        <Select
          className="w-full border rounded-md p-2"
          options={questionOptions}
          value={node.questionType}
          onChange={(type) => {
            onUpdate({ ...node, questionType: type });
          }}
        />
        {node.questionType === "multipleChoice" && (
          <div className="flex flex-col gap-2 mt-2">
            {options.map((option, i) => (
              <div key={i}>
                <Input
                  suffixIcon={
                    <div
                      className="cursor-pointer"
                      onClick={() => removeOption(i)}
                    >
                      <IconClose />
                    </div>
                  }
                  value={option.label || ""}
                  onChange={(label) => {
                    const newOptions = [...options];
                    newOptions[i] = { ...option, label };
                    onUpdate({ ...node, options: newOptions });
                  }}
                />
              </div>
            ))}
            <div
              onClick={addOption}
              className="cursor-pointer w-full h-10 px-6 py-2.5 bg-white rounded-[8px] border-[1.5px] border-blue-500 justify-center items-center gap-2 inline-flex"
            >
              <div className="text-center text-blue-500 text-sm flex items-center font-bold gap-2">
                <IconPlus className="text-blue-500" />
                <span> Add Option</span>
              </div>
            </div>
          </div>
        )}
      </FormGroup>
      <Divider />
      <FormGroup label="Save Reply">
        <ToggleGroup
          toggled={node.saveAsContactProp || false}
          label="Contact Property"
          changeToggle={() => {
            onUpdate({
              ...node,
              saveAsContactProp: !node.saveAsContactProp
            });
          }}
        />
        <ToggleGroup
          toggled={node.saveAsConversationProp || false}
          label="Conversation Property"
          changeToggle={() => {
            onUpdate({
              ...node,
              saveAsConversationProp: !node.saveAsConversationProp
            });
          }}
        />
        <ToggleGroup
          toggled={node.saveAsWorkflowProp || false}
          label="Workflow Property"
          changeToggle={() => {
            onUpdate({
              ...node,
              saveAsWorkflowProp: !node.saveAsWorkflowProp
            });
          }}
        />
        {(node.saveAsContactProp ||
          node.saveAsConversationProp ||
          node.saveAsWorkflowProp) && (
          <Input
            placeholder="Property Name"
            value={node.propName}
            onChange={(propName) => onUpdate({ ...node, propName })}
          />
        )}
      </FormGroup>
      {node.questionType !== "multipleChoice" && (
        <>
          <Divider />
          <FormGroup label="Invalid Reply Action">
            <Select
              className="w-full border rounded-md p-2"
              options={{
                repeat: "Repeat the question",
                failBranch: "Proceed to a fail branch"
              }}
              value={node.invalidReplyAction}
              onChange={toggleInvalidReplyAction}
            />
          </FormGroup>
        </>
      )}
    </>
  );
}
