import ActionMenu from "@/components/ActionMenu";
import useApi from "@/hooks/useApi";
import useDeactivate from "@/hooks/useDeactivate";
import useRoleListItems from "@/hooks/useRoleListItems";
import useTeamListItems from "@/hooks/useTeamListItems";
import IconArrowBack2 from "@/icons/IconArrowBack2";
import IconDeactivateUserPerson from "@/icons/IconDeactivateUserPerson";
import IconMoreHorizontalDots from "@/icons/IconMoreHorizontalDots";
import IconPencil from "@/icons/IconPencil";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MenuItem } from "../types";
import DeactivateUserModal from "./DeactivateUserModal";
import EditUserPanel from "./EditUserPanel";
import { UserListItem } from "./types";
import UserProfileBanner from "./UserProfileBanner";
import useSkillListItems from "@/hooks/useSkillListItems";

export default function UserProfile() {
  const api = useApi();
  const navigate = useNavigate();
  const deactivate = useDeactivate();
  const { userId: stringUserId } = useParams<{ userId: string }>();
  const userId = parseInt(stringUserId!);
  const [user, setUser] = useState<UserListItem | null>(null);
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [editUserId, setEditUserId] = useState<number | undefined>();
  const [loading, setLoading] = useState(true);

  const fetchUser = React.useCallback(async () => {
    const response = api.messaging.get(`Users/${userId}`);
    setUser(await (await response).json());
    setLoading(false);
  }, [api.messaging, userId]);

  React.useEffect(() => {
    fetchUser();
  }, [api.messaging, fetchUser, userId]);

  const { getTeamNameById } = useTeamListItems();
  const { getRoleNameById } = useRoleListItems();
  const { getSkillNameById } = useSkillListItems();

  function getTeams(user: UserListItem | null) {
    if (!user) return null;

    if (user.teamIds?.length === 0) {
      return "None";
    }

    const teams = user.teamIds.map((x) => getTeamNameById(x));

    return teams.join(", ");
  }

  function getRoles(user: UserListItem | null) {
    if (user?.roles?.length === 0 || user?.roles === null) {
      return "(None)";
    }

    const roleNames = user?.roles.map((x) => {
      const parsedNumber = parseInt(x);
      if (!isNaN(parsedNumber)) {
        return getRoleNameById(parsedNumber);
      } else {
        return x;
      }
    });
    return roleNames?.join(", ");
  }

  function getSkills(user: UserListItem | null) {
    if (!user) return null;

    if (user.skillIds?.length === 0) {
      return "None";
    }
    const skills = user.skillIds.map((x) => getSkillNameById(x));
    return skills.join(", ");
  }

  async function handleDeactivateUser() {
    const response = await deactivate.deactivateUser(user?.id);

    if (response?.ok) {
      navigate("/users");
    } else {
      alert("Failed to delete user.");
    }
  }

  const actionMenuItems: MenuItem[] = [
    {
      icon: <IconDeactivateUserPerson className="m-2 w-8" />,
      label: "Deactivate User",
      onClick: () => setShowDeactivateModal(true)
    }
  ];

  if (loading) {
    return <div>Loading...</div>;
  } else if (editUserId) {
    return (
      <EditUserPanel
        userId={editUserId}
        onClose={() => {
          setEditUserId(undefined);
          fetchUser();
        }}
        onUserUpdated={() => {
          setEditUserId(undefined);
          fetchUser();
        }}
      />
    );
  }

  return (
    <div>
      <UserProfileBanner user={user} />
      <div className="panel bg-white dark:bg-stone-300 rounded-md shadow-md mb-4 p-4 grid grid-cols-2 gap-4">
        <div>
          <div className="flex justify-between">
            <div className="font-semibold text-lg">Details</div>
            <div className="flex">
              <div className="hover:text-cornflower-blue-500">
                <IconPencil
                  className="mr-4 cursor-pointer fill-current "
                  onClick={() => setEditUserId(user?.id)}
                />
              </div>
              <div
                className="relative cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowActionMenu(!showActionMenu);
                }}
              >
                <div className="hover:text-cornflower-blue-500">
                  <IconMoreHorizontalDots />
                </div>
                {showActionMenu && (
                  <ActionMenu
                    items={actionMenuItems}
                    onClose={() => setShowActionMenu(false)}
                    width={"w-72"}
                    onClick={(index: number) => {
                      actionMenuItems[index].onClick();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex">
            <div className="w-1/3 font-semibold">First Name</div>
            <div className="w-2/3">{user?.firstName}</div>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex">
            <div className="w-1/3 font-semibold">Last Name</div>
            <div className="w-2/3">{user?.lastName}</div>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex">
            <div className="w-1/3 font-semibold">Email</div>
            <div className="w-2/3">{user?.email}</div>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex">
            <div className="w-1/3 font-semibold">Display name</div>
            <div className="w-2/3">{user?.displayName}</div>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex">
            <div className="w-1/3 font-semibold">Roles</div>
            <div className="w-2/3">{getRoles(user)}</div>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex ">
            <div className="w-1/3 font-semibold flex-1">Teams</div>
            <div className="w-2/3">{getTeams(user)}</div>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex ">
            <div className="w-1/3 font-semibold flex-1">Skills</div>
            <div className="w-2/3">{getSkills(user)}</div>
          </div>
          <hr className="my-4 border-gray-100" />
          {/* <div className="flex">
            <div className="w-1/3 font-semibold">Skills</div>
            <div className="w-2/3">Skills</div>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex">
            <div className="w-1/3 font-semibold">Schedule</div>
            <div className="w-2/3">Schedule</div>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex">
            <div className="w-1/3 font-semibold">Chat Limit</div>
            <div className="w-2/3">Chat Limit</div>
          </div>
          <hr className="my-4 border-gray-100" /> */}
        </div>
        {/* TODO: Divider */}
        {/* <div className=" absolute border-l border-gray-300 h-full my-auto"></div> */}
        <div>
          {/* <div className="font-semibold text-lg">User Activity Timeline</div>
          <hr className="my-4 border-gray-100" /> */}
        </div>
      </div>
      {showDeactivateModal && (
        <DeactivateUserModal
          show={showDeactivateModal}
          onClose={() => {
            setShowDeactivateModal(false);
          }}
          onDeactivate={() => handleDeactivateUser()}
        />
      )}
    </div>
  );
}

export function BackToUsersButton() {
  const navigate = useNavigate();
  return (
    <div className="flex cursor-pointer" onClick={() => navigate("/users")}>
      <IconArrowBack2 />
      <div className="ml-2 hover:underline">Back to all users</div>
    </div>
  );
}
