import React, { useState } from "react";
import WizardStepIndicator from "./WizardStepIndicator";
import ProfileWizardStep from "./ProfileWizardStep";
import AssignTeamsWizardStep from "./AssignTeamsWizardStep";
import { UserModel } from "../types";
import ReviewWizardStep from "./ReviewWizardStep";
import useTeamListItems from "@/hooks/useTeamListItems";
import { TeamListItem } from "@/providers/TeamListItemsProvider";
import SuccessWizardStep from "./SuccessWizardStep";

function getDefaultUserModel(teams: TeamListItem[]) {
  return {
    displayName: "",
    firstName: "",
    lastName: "",
    email: "",
    teamIds: teams.length === 1 ? [teams[0].id] : [],
    roles: [],
    picture: "",
    id: -1
  };
}

export default function AddUserWizard() {
  const { teams } = useTeamListItems();
  const [activeStep, setActiveStep] = useState(1);
  const [showSuccessPanel, setShowSuccessPanel] = useState(false);
  const [user, setUser] = useState<UserModel>(getDefaultUserModel(teams));
  const stepLabels = ["Profile", "Assign teams", "Review and invite"];

  if (showSuccessPanel) {
    return (
      <SuccessWizardStep
        user={user}
        setActiveStep={setActiveStep}
        setShowSuccessModal={setShowSuccessPanel}
      />
    );
  }

  return (
    <div>
      <div className="flex justify-center mt-6">
        <WizardStepIndicator stepLabels={stepLabels} activeStep={activeStep} />
      </div>
      {activeStep === 1 && (
        <ProfileWizardStep
          setActiveStep={setActiveStep}
          user={user}
          setUser={setUser}
        />
      )}
      {activeStep === 2 && (
        <AssignTeamsWizardStep
          user={user}
          setUser={setUser}
          setActiveStep={setActiveStep}
        />
      )}
      {activeStep === 3 && (
        <ReviewWizardStep
          user={user}
          setUser={setUser}
          setActiveStep={setActiveStep}
          setShowSuccessPanel={setShowSuccessPanel}
        />
      )}
    </div>
  );
}
