import * as React from "react";
import useModelState from "../hooks/useModelState";
import UploadButton from "./UploadButton";
import useApi from "@/hooks/useApi";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import FormGroup from "@velaro/velaro-shared/src/components/FormGroup";
import useTeamListItems from "@/hooks/useTeamListItems";
import ColorPicker from "@/components/ColorPicker";

export default function Index() {
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const [companyName, setCompanyName] = React.useState("");
  const [themeColor, setThemeColor] = React.useState("");
  const [headerText, setHeaderText] = React.useState("");
  const [returnLinkText, setReturnLinkText] = React.useState("");
  const [returnLinkUrl, setReturnLinkUrl] = React.useState("");
  const [logoUrl, setLogoUrl] = React.useState("");
  const [enableLiveChat, setEnableLiveChat] = React.useState(false);
  const [liveChatGroupId, setLiveChatGroupId] = React.useState<number>(0);
  const [modelState, setModelState] = useModelState();
  const { teams } = useTeamListItems();
  const api = useApi();

  React.useEffect(() => {
    async function fetchTenant() {
      setLoading(true);
      const response = await api.main.get("KBSettings");
      const json = await response.json();
      setCompanyName(json.CompanyName);
      setThemeColor(json.ThemeColor);
      setHeaderText(json.HeaderText);
      setEnableLiveChat(json.EnableLiveChat);
      setLiveChatGroupId(json.LiveChatGroupId);
      setReturnLinkText(json.ReturnLinkText || "");
      setReturnLinkUrl(json.ReturnLinkUrl || "");
      setLogoUrl(json.LogoUrl || "");
      setLoading(false);
    }

    fetchTenant();
  }, []);

  async function handleSubmit() {
    setSaving(true);

    const resp = await api.main.post("KBSettings", {
      companyName,
      headerText,
      themeColor,
      enableLiveChat,
      liveChatGroupId,
      returnLinkText,
      returnLinkUrl,
      logoUrl
    });

    if (resp.status === 400) {
      console.error(resp);
    }

    setSaving(false);
  }

  async function uploadFile(file: File) {
    const formData = new FormData();
    formData.append("logo", file);

    const response = await api.main.post("KBSettings/UploadLogo", {
      data: formData,
      contentType: false,
      processData: false
    });
    const json = await response.json();

    setLogoUrl(json.url);
  }

  if (loading) {
    return (
      <div style={{ marginTop: 100 }}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="kb-container" style={{ maxWidth: 400 }}>
      <div className="kb-header">
        <h1>Settings</h1>
        {/* <h1>{App.translate("app/kb/kbsettings")}</h1> */}
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <FormGroup label="CompanyName">
          <input
            type="text"
            className="form-control"
            id="company-name"
            value={companyName}
            onChange={(e) => setCompanyName(e.currentTarget.value)}
          />
        </FormGroup>
        <FormGroup label="Logo">
          {/* <FormGroup label="Logo" modelState={modelState} validationField="model.Logo"> */}
          {/* <label htmlFor="logo">{App.translate("app/kb/logo")}</label> */}
          <div>
            {logoUrl && (
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <img src={logoUrl} alt="" style={{ height: 42 }} />
                <button
                  type="button"
                  className="btn btn-sm btn-danger"
                  style={{ marginLeft: 16 }}
                  onClick={() => {
                    setLogoUrl("");
                  }}
                >
                  <i className="fa fa-times"></i>
                </button>
              </div>
            )}
            <UploadButton onChange={(file) => uploadFile(file)} />
          </div>
        </FormGroup>
        <FormGroup label="Return Link Text">
          {/* <FormGroup
          modelState={modelState}
          validationField="model.ReturnLinkText"
        >
          <label htmlFor="return-link-text">
            Return Link Text
            {/* {App.translate("app/kb/returnlinktext")} */}
          {/* </label> */}
          <input
            type="text"
            className="form-control"
            id="return-link-text"
            value={returnLinkText}
            onChange={(e) => setReturnLinkText(e.currentTarget.value)}
          />
        </FormGroup>
        <FormGroup label="Return Link URL">
          {/* <FormGroup
          modelState={modelState}
          validationField="model.ReturnLinkUrl"
        >
          <label htmlFor="return-link-url">
            return link url
            {/* {App.translate("app/kb/returnlinkurl")} */}
          {/* </label> */}
          <input
            type="url"
            className="form-control"
            id="return-link-url"
            value={returnLinkUrl}
            onChange={(e) => setReturnLinkUrl(e.currentTarget.value)}
          />
        </FormGroup>
        <FormGroup label="Theme Color">
          {/* <FormGroup>
          <label>Theme Color</label> */}
          {/* <label>{App.translate("app/kb/themecolor")}</label> */}
          <ColorPicker
            value={themeColor}
            onChange={(value) => setThemeColor(value)}
          />
        </FormGroup>
        <FormGroup label="Header Text">
          {/* <label htmlFor="header-text">
            Header Text
            {/* {App.translate("app/kb/headertext")} */}
          {/* </label> */}
          <input
            type="text"
            className="form-control"
            id="header-text"
            value={headerText}
            onChange={(e) => setHeaderText(e.currentTarget.value)}
          />
        </FormGroup>
        <div className="checkbox">
          <label>
            <input
              type="checkbox"
              checked={enableLiveChat}
              onChange={(e) => setEnableLiveChat(e.currentTarget.checked)}
            />
            Enable live chat
          </label>
        </div>
        {enableLiveChat && (
          <FormGroup label="Live chat group">
            {/* <label htmlFor="live-chat-group">Live chat group</label> */}
            <select
              id="live-chat-group"
              className="form-control"
              value={liveChatGroupId}
              onChange={(e) =>
                setLiveChatGroupId(parseInt(e.currentTarget.value))
              }
            >
              {teams.map((group) => (
                <option key={group.id} value={group.id}>
                  {group.name}
                </option>
              ))}
            </select>
          </FormGroup>
        )}
        <button type="submit" className="btn btn-success" disabled={saving}>
          {saving
            ? "Saving" //App.translate("app/shared/saving")
            : "Save"}
          {/* //App.translate("app/shared/save") */}
        </button>
      </form>
    </div>
  );
}
