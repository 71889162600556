import useApi from "@/hooks/useApi";
import React, { useCallback, useEffect, useState } from "react";
import { TeamListItemsContext } from "../context/TeamListItemsContext";
import { LoadingIndicator } from "react-select/dist/declarations/src/components/indicators";
import LoadingSpinner from "@/components/LoadingSpinner";

export interface TeamListItem {
  id: number;
  name: string;
  autoRoute: boolean;
  routingType: string;
  isDefault: boolean;
  autoUnassign: boolean;
  unassignWarningTime: number;
  unassignTime: number;
}

// make this bad boy lazy???
export function TeamListItemsProvider(props: { children: React.ReactNode }) {
  const api = useApi();
  const [teams, setTeams] = React.useState<TeamListItem[] | null>(null);
  const [loading, setLoading] = useState(true);

  const fetch = useCallback(async () => {
    const response = await api.messaging.get("Teams/List");
    const data = await response.json();
    setTeams(data);
    setLoading(false);
    return data;
  }, [api.messaging]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  function getTeamNameById(teamId: number) {
    return teams?.find((x) => x.id === teamId)?.name || "";
  }

  function getTeamById(teamId: number) {
    return teams?.find((x) => x.id === teamId) || null;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <TeamListItemsContext.Provider
      value={{ teams: teams || [], getTeamNameById, setTeams, getTeamById }}
    >
      {props.children}
    </TeamListItemsContext.Provider>
  );
}
