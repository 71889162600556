import * as React from "react";
import { FormGroup } from "../RightAsideHelpers";
import AskQuestionEditor from "./AskQuestionEditor";
import SendMessageEditor from "./SendMessageEditor";
import Input from "@/components/Input";
import JumpToEditor from "./JumpToEditor";
import { WorkflowNode } from "../../types";
import ConditionEditor from "./ConditionEditor";

interface Props {
  node: WorkflowNode;
  onUpdate: (node: WorkflowNode) => void;
}

export default function ActionEditorBase({ node, onUpdate }: Props) {
  function renderActionEditor() {
    switch (node.actionType) {
      case "askQuestion":
        return <AskQuestionEditor node={node} onUpdate={onUpdate} />;
      case "sendMessage":
        return <SendMessageEditor node={node} onUpdate={onUpdate} />;
      case "jumpTo":
        return <JumpToEditor node={node} onUpdate={onUpdate} />;
      case "condition":
        return <ConditionEditor node={node} onUpdate={onUpdate} />;
      default:
        return null;
    }
  }
  return (
    <>
      <FormGroup label="Node Name">
        <Input
          value={node.name || ""}
          onChange={(v) => onUpdate({ ...node, name: v })}
        />
      </FormGroup>
      {renderActionEditor()}
    </>
  );
}
