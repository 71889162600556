import React, { useEffect, useState } from "react";
import CustomizableSelect from "@velaro/velaro-shared/src/components/inputs/CustomizableSelect";
import Toggle from "@velaro/velaro-shared/src/components/Toggle";
import AIChatbotSettingsRow from "./AIChatbotSettingsRow";
import AIChatbotSettingsRowToggle from "./AIChatbotSettingsRowToggle";
import AIChatbotSettingsTabs from "./AIChatbotSettingsTabs";
import useAIConfigs from "@/hooks/useAIConfigs";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import IconPlus from "@velaro/velaro-shared/src/icons/IconPlus";
import IconText from "@velaro/velaro-shared/src/icons/IconText";
import IconHash from "@velaro/velaro-shared/src/icons/IconHash";
import IconSearchKB from "@velaro/velaro-shared/src/icons/IconSearchKB";
import { useParams } from "react-router-dom";
import AddNewVariableModal from "./AddNewVariableModal";
import { set } from "lodash";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";

export default function AIChatbotSettingsStarterQuestions() {
  const { id } = useParams();
  const { aiConfigurations, loading, update } = useAIConfigs();
  const [showModal, setShowModal] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  function handleVariableType(arg0: string) {
    console.log(arg0);
    setShowModal(true);
  }

  function handleQuestionChange(e: any) {
    if (e.target.value.length > 120) {
      return;
    }
    setQuestion(e.target.value);
  }

  function handleAnswerChange(e: any) {
    if (e.target.value.length > 500) {
      return;
    }
    setAnswer(e.target.value);
  }

  const variableTypes = [
    {
      label: "Add new variable",
      action: () => handleVariableType("new"),
      icon: <IconPlus />
    },
    {
      label: "Name",
      action: () => handleVariableType("name"),
      icon: <IconText />
    },
    {
      label: "Email",
      action: () => handleVariableType("email"),
      icon: <IconText />
    },
    {
      label: "Address",
      action: () => handleVariableType("address"),
      icon: <IconText />
    },
    {
      label: "Contact Number",
      action: () => handleVariableType("number"),
      icon: <IconHash />
    },
    {
      label: "Company Size",
      action: () => handleVariableType("size"),
      icon: <IconHash />
    },
    {
      label: "Role",
      action: () => handleVariableType("role"),
      icon: <IconText />
    }
  ];

  if (loading) return <LoadingSpinner />;

  return (
    <>
      <AIChatbotSettingsTabs selectedTab="Starter Questions" aiConfigId={id!} />
      <div className="bg-white p-6 border-[1px] rounded-2xl shadow-sm w-[750px] self-center">
        <div className="border-b-[1px]">
          <div className="font-semibold text-lg pb-1">
            Starter Questions (Optional)
          </div>
          <div className="text-slate-500 text-sm pb-2">
            Starter Questions helps your visitors to start a conversation with
            your chatbot.
          </div>
        </div>
        <div className="py-3">
          <div className="flex flex-col rounded-2xl border border-slate-200">
            <div className="font-semibold text-sm p-2">{"Question"}</div>
            <div className="p-2">
              <input
                value={question}
                onChange={(e) => {
                  handleQuestionChange(e);
                }}
                type="text"
                placeholder="Type question here"
                className="w-full p-2 border-[1px] rounded-md text-sm"
              />
              <div className="flex justify-end text-xs text-slate-500 pt-1">
                {answer.length}/120
              </div>
            </div>
            <div className="font-semibold text-sm p-2">{"Answer"}</div>
            <div className="p-2">
              <textarea
                placeholder="Typer answer here"
                value={answer}
                className="w-full box-border p-2 border-[1px] rounded-md text-sm"
                onChange={(e) => handleAnswerChange(e)}
                rows={5}
              />
              <div className="flex justify-end text-xs text-slate-500">
                {answer.length}/500
              </div>
            </div>
          </div>
        </div>
        <PrimaryBlueButton
          label="Add Suggested Question"
          prefixIcon={<IconPlus />}
          disabled={question.length === 0 || answer.length === 0}
          onClick={() => {
            console.log("Add Question");
          }}
        />
        <div className="py-3">
          <div className="font-semibold text-sm pb-1">{"Questions"}</div>
          <div className="flex flex-col rounded-2xl border border-slate-200 justify-center items-center py-12">
            <div className="flex">
              <IconSearchKB />
            </div>
            <div className="flex font-semibold pt-2">
              No Suggested Questions to show
            </div>
          </div>
        </div>
      </div>
      {showModal && <AddNewVariableModal close={() => setShowModal(false)} />}
    </>
  );
}
