import useApi from "@/hooks/useApi";
import React, { useCallback, useEffect, useState } from "react";
import useTeamListItems from "@/hooks/useTeamListItems";
import IconTrash1 from "@/icons/IconTrash1";
import IconMoreHorizontalDots from "@/icons/IconMoreHorizontalDots";
import IconEditPencil from "@/icons/IconEditPencil";
import { MenuItem } from "../types";
import IconDeactivateUserPerson from "@/icons/IconDeactivateUserPerson";
import ActionMenu from "@/components/ActionMenu";
import EditDeploymentPanel from "./EditDeploymentPanel";
import DeactivateDeploymentModal from "./DeactivateDeploymentModal";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import AddDeploymentPanel from "./AddDeploymentPanel";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";

export interface Deployment {
  id: number;
  deploymentId: string;
  displayName: string;
  teamId: number;
  siteId: number;
}

export default function Deployments() {
  const api = useApi();
  const [deployments, setDeployments] = useState<Deployment[]>([]);
  const [loadingDeployments, setLoadingDeployments] = useState(true);
  const [activeDeploymentMenu, setActiveDeploymentMenu] =
    useState<number | undefined>(undefined);
  const [editDeploymentId, setEditDeploymentId] =
    useState<number | undefined>();
  const [deleteDeploymentId, setDeleteDeploymentId] =
    useState<number | undefined>();
  const [showAddDeploymentPanel, setShowAddDeploymentPanel] = useState(false);
  const toastAlert = useToastAlert();

  const { getTeamNameById } = useTeamListItems();

  const fetchDeployments = useCallback(async () => {
    setLoadingDeployments(true);
    const resp = await api.messaging.get(`Deployment`);
    setDeployments(await resp.json());
    setLoadingDeployments(false);
  }, [api.messaging]);

  useEffect(() => {
    fetchDeployments();
  }, [fetchDeployments]);

  async function handleDeactivate() {
    if (!deleteDeploymentId) {
      alert("Failed to deactivate deployment.");
      return;
    }

    const response = await api.messaging.delete(
      `Deployment/${deleteDeploymentId}`
    );

    if (response?.ok) {
      toastAlert.displayToast(
        Severity.Success,
        "Deployment deactivated successfully"
      );
      setDeleteDeploymentId(undefined);
    } else {
      toastAlert.displayToast(
        Severity.Error,
        "Failed to deactivate deployment"
      );
    }

    const index = deployments.findIndex((x) => x.id === deleteDeploymentId);
    const clone = [...deployments];
    clone.splice(index, 1);
    setDeployments(clone);
  }

  const deleteDeployment = useCallback(
    async (id: number) => {
      setDeployments((oldState) => {
        return oldState.filter((x) => x.id != id);
      });
      await api.messaging.delete(`Deployment/${id}`);
    },
    [api.messaging]
  );

  const handleActionsMenuToggle = (id: number) => {
    setActiveDeploymentMenu(activeDeploymentMenu === id ? undefined : id);
  };

  const actionMenuItems: MenuItem[] = [
    {
      icon: <IconEditPencil className="m-2 w-8" />,
      label: "Edit Deployment",
      onClick: (id: number) => setEditDeploymentId(id)
    },
    {
      icon: <IconDeactivateUserPerson className="m-2 w-8" />,
      label: "Deactivate Deployment",
      onClick: (id: number) => setDeleteDeploymentId(id)
    }
  ];

  if (editDeploymentId) {
    return (
      <EditDeploymentPanel
        Id={editDeploymentId}
        onClose={() => {
          setEditDeploymentId(undefined);
          fetchDeployments();
        }}
      />
    );
  }

  if (showAddDeploymentPanel) {
    return (
      <AddDeploymentPanel
        onClose={() => {
          setShowAddDeploymentPanel(false);
          fetchDeployments();
        }}
        onDeploymentAdded={() => {
          setShowAddDeploymentPanel(false);
          fetchDeployments();
        }}
      />
    );
  }

  return (
    <div>
      <div className="flex items-center pb-4 mt-2">
        <PrimaryBlueButton
          label="Add Deployment"
          onClick={() => setShowAddDeploymentPanel(true)}
        />
      </div>
      <div className="panel bg-white dark:bg-stone-300 rounded-md shadow-md mb-4">
        <table className="w-full">
          <thead>
            <tr>
              <th className={thClassName}>Display Name</th>
              <th className={thClassName}>Deployment Id</th>
              <th className={thClassName}>Team</th>
              <th className={thClassName} style={{ width: 1 }}></th>
            </tr>
          </thead>
          <tbody>
            {deployments.map((deployment) => (
              <tr key={deployment.id}>
                <td className={tdClassName}>{deployment.displayName}</td>
                <td className={tdClassName}>{deployment.deploymentId}</td>
                <td className={tdClassName}>
                  {getTeamNameById(deployment.teamId)}
                </td>
                <td className={`${tdClassName} text-sm whitespace-nowrap`}>
                  <div
                    className="relative cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleActionsMenuToggle(deployment.id);
                    }}
                  >
                    <IconMoreHorizontalDots />
                    {activeDeploymentMenu === deployment.id && (
                      <ActionMenu
                        items={actionMenuItems}
                        onClose={() => setActiveDeploymentMenu(undefined)}
                        onClick={(index: number) => {
                          actionMenuItems[index].onClick(deployment.id);
                        }}
                      />
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <DeactivateDeploymentModal
        show={!!deleteDeploymentId}
        onClose={() => setDeleteDeploymentId(undefined)}
        onDeactivate={() => handleDeactivate()}
      />
    </div>
  );
}

const thClassName = "border-b p-4 text-left";
const tdClassName = "border-b px-4 py-2 ";
