import * as React from "react";
import { WorkflowNode } from "../../types";
import TextArea from "@/components/TextArea";
import { FormGroup } from "../RightAsideHelpers";

interface Props {
  node: WorkflowNode;
  onUpdate: (node: WorkflowNode) => void;
}

export default function SendMessageEditor({ node, onUpdate }: Props) {
  return (
    <>
      <FormGroup label="Message">
        <TextArea
          value={node.text}
          onChange={(e) => onUpdate({ ...node, text: e.currentTarget.value })}
        />
      </FormGroup>
    </>
  );
}
