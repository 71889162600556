import React, { useState } from "react";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import {
  PrimaryBlueButton,
  SecondaryBlueButton
} from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import CustomizableSelect from "@velaro/velaro-shared/src/components/inputs/CustomizableSelect";
import IconText from "@velaro/velaro-shared/src/icons/IconText";
import IconHash from "@velaro/velaro-shared/src/icons/IconHash";
import AIChatbotSettingsRow from "./AIChatbotSettingsRow";

interface Props {
  close: () => void;
}

export default function AddNewVariableModal(props: Props) {
  function handleVariableType(arg0: string) {
    console.log(arg0);
  }
  const dataTypes = [
    {
      label: "Text",
      action: () => handleVariableType("text"),
      icon: <IconText />
    },
    {
      label: "Number",
      action: () => handleVariableType("number"),
      icon: <IconHash />
    }
  ];

  return (
    <Modal maxWidth="max-w-3xl" show={true}>
      <Modal.Header title={"Add new variable"} onClose={props.close} />
      <Modal.Body>
        <div className="flex p-4">
          <div className="w-1/2 pr-2">
            <div className="font-semibold">Name</div>
            <input
              type="text"
              placeholder="Enter a name"
              className="w-full p-2 border-[1px] rounded-md text-sm"
            />
          </div>
          <div className="w-1/2">
            <div className="font-semibold">Data Type</div>
            <CustomizableSelect options={dataTypes} />
          </div>
        </div>
        <div className="py-2 px-4">
          <AIChatbotSettingsRow
            title="Description"
            subtitle="An internal description for the variable"
            input={
              <input
                type="text"
                placeholder="Enter a description"
                className="w-full p-2 border-[1px] rounded-md text-sm"
              />
            }
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex p-4">
          <div className="flex ml-auto">
            <div className="flex pr-2">
              <SecondaryBlueButton
                label="Cancel"
                onClick={() => {
                  props.close();
                }}
              />
            </div>
            <PrimaryBlueButton
              label="Add"
              onClick={() => {
                console.log("add");
              }}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
