import React from "react";
import { TeamListItem } from "@/providers/TeamListItemsProvider";
export const TeamListItemsContext = React.createContext<{
  teams: TeamListItem[];
  getTeamNameById: (TeamId: number) => string;
  setTeams: (teams: TeamListItem[]) => void;
  getTeamById: (TeamId: number) => TeamListItem | null;
}>({
  teams: [],
  getTeamNameById: () => "",
  setTeams: () => {},
  getTeamById: () => null
});
