import ColorPicker from "@/components/ColorPicker";
import React, { useState } from "react";
import useStyleConfigs from "../../hooks/useStyleConfigs";
import LogoUpload from "./LogoUpload";
import { StyleConfigModel } from "@/pages/Messaging/types";
import useApi from "@/hooks/useApi";
import { Link } from "react-router-dom";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";

interface Props {
  selectedConfig: StyleConfigModel;
  setWorkingConfig: (config: StyleConfigModel) => void;
  workingConfig: StyleConfigModel;
}

export default function StyleForm(props: Props) {
  const [saving, setSaving] = useState(false);
  const [stagedLogoFile, setStagedLogoFile] = useState<File>();
  const api = useApi();
  const { styleConfigs, setStyleConfigs } = useStyleConfigs();
  const toastAlert = useToastAlert();

  async function persist() {
    setSaving(true);

    const resp = await api.messaging.post("MessengerStyleConfig", {
      ...props.workingConfig,
      logoUrl: undefined
    });
    const result = await resp.json();

    if (resp.ok) {
      await uploadLogo();
      const clonedStyleConfigs = [...styleConfigs];
      const index = clonedStyleConfigs.findIndex((x) => x.id === result.id);
      clonedStyleConfigs[index] = result;
      setStyleConfigs(clonedStyleConfigs);
      setStagedLogoFile(undefined);
      toastAlert.displayToast(
        Severity.Success,
        "Style Config saved successfully"
      );
    } else {
      toastAlert.displayToast(Severity.Error, "Failed to save style config");
    }
    setSaving(false);
  }

  async function uploadLogo() {
    if (!stagedLogoFile) {
      return;
    }

    const form = new FormData();
    form.append("logo", stagedLogoFile);
    form.append("id", props.workingConfig.id.toString());

    const resp = await api.messaging.postFormData(
      "MessengerStyleConfig/UploadLogo",
      form
    );
  }

  function update(updates: Partial<StyleConfigModel>) {
    props.setWorkingConfig({ ...props.workingConfig, ...updates });
  }

  function reset() {
    props.setWorkingConfig(props.selectedConfig);
    setStagedLogoFile(undefined);
  }

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          persist();
        }}
        className="w-full"
      >
        <div className="mb-4">
          <label className="block mb-2">Name</label>
          <input
            type="text"
            value={props.workingConfig.name}
            onChange={(e) => update({ name: e.currentTarget.value })}
            className="input w-full"
          />
        </div>
        <div className="mb-4 border-y py-4">
          <label>Use this configuration under the following conditions:</label>
          <div className="flex mt-2 items-center">
            <div className="flex items-center">
              <label className="block whitespace-nowrap">Domain is</label>
              <input
                type="text"
                value={props.workingConfig.domain || ""}
                onChange={(e) => update({ domain: e.currentTarget.value })}
                className="input w-full ml-2"
              />
            </div>
            <Link to={"/rules"}>
              <div className="ml-8 hover:underline text-cornflower-blue-500">
                Or, create a custom rule
              </div>
            </Link>
          </div>
        </div>
        <div className="mb-4">
          <LogoUpload
            setStageLogoFile={setStagedLogoFile}
            update={update}
            workingConfig={props.workingConfig}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Background color</label>
          <ColorPicker
            value={props.workingConfig.backgroundColor}
            onChange={(backgroundColor) => update({ backgroundColor })}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Action color</label>
          <ColorPicker
            value={props.workingConfig.actionColor}
            onChange={(actionColor) => update({ actionColor })}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Launcher position</label>
          <select
            className="input"
            value={props.workingConfig.launcherPosition}
            onChange={(e) =>
              update({
                launcherPosition: e.currentTarget.value as any
              })
            }
          >
            <option value="Right">Right</option>
            <option value="Left">Left</option>
          </select>
        </div>
        <div className="flex mb-4">
          <div className="mr-4">
            <label className="block mb-2">Side spacing</label>
            <input
              type="number"
              className="input"
              value={props.workingConfig.sideSpacing}
              onChange={(e) =>
                update({ sideSpacing: e.currentTarget.value as any })
              }
            />
          </div>
          <div>
            <label className="block mb-2">Bottom spacing</label>
            <input
              type="number"
              className="input"
              value={props.workingConfig.bottomSpacing}
              onChange={(e) =>
                update({ bottomSpacing: e.currentTarget.value as any })
              }
            />
          </div>
        </div>
        <div className="flex mb-4">
          <label>Show when agent is typing to visitor</label>
          <input
            className="ml-2"
            type="checkbox"
            checked={props.workingConfig.showAgentTyping}
            onChange={() =>
              update({
                showAgentTyping: !props.workingConfig.showAgentTyping
              })
            }
          ></input>
        </div>
        <div className="flex mb-4">
          <label>
            Show when agent has recieved or read messages to visitors
          </label>
          <input
            className="ml-2"
            type="checkbox"
            checked={props.workingConfig.showAgentMessageStatus}
            onChange={() =>
              update({
                showAgentMessageStatus:
                  !props.workingConfig.showAgentMessageStatus
              })
            }
          ></input>
        </div>
        <div>
          <button type="submit" className="btn-green mr-2" disabled={saving}>
            {saving ? "Saving..." : "Save and set live"}
          </button>
          <button type="button" className="btn-link" onClick={() => reset()}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
