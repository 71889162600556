import SendIcon from "@velaro/velaro-shared/src/svg/stroke-icons/send-03.svg?react";
import ConditionIcon from "@velaro/velaro-shared/src/svg/stroke-icons/dataflow-04.svg?react";
import TimeDelayIcon from "@velaro/velaro-shared/src/svg/stroke-icons/clock-rewind.svg?react";
import AssignToIcon from "@velaro/velaro-shared/src/svg/stroke-icons/user-plus-01.svg?react";
import JumptoIcon from "@velaro/velaro-shared/src/svg/stroke-icons/flip-backward.svg?react";
import AiIcon from "@velaro/velaro-shared/src/svg/stroke-icons/robot.svg?react";
import TriggerWorkflowIcon from "@velaro/velaro-shared/src/svg/stroke-icons/zap.svg?react";
import AskQuestionIcon from "@velaro/velaro-shared/src/svg/stroke-icons/message-question-square.svg?react";
import CloseConversationIcon from "@velaro/velaro-shared/src/svg/stroke-icons/message-check-square.svg?react";
import TagIcon from "@velaro/velaro-shared/src/svg/stroke-icons/tag-01.svg?react";
import NoteIcon from "@velaro/velaro-shared/src/svg/stroke-icons/file-plus-02.svg?react";
import HttpIcon from "@velaro/velaro-shared/src/svg/stroke-icons/link-02.svg?react";
import SendTranscriptIcon from "@velaro/velaro-shared/src/svg/stroke-icons/file-02.svg?react";
import CollectDataIcon from "@velaro/velaro-shared/src/svg/stroke-icons/data.svg?react";
import PlayIcon from "@velaro/velaro-shared/src/svg/stroke-icons/play.svg?react";

import { SvgIcon } from "@/types";
import { dayOfWeekDefs, DayOfWeek } from "@/components/RuleBuilder/types";
import IconEmail from "@/icons/IconEmail";
import IconFilePlus from "@/icons/IconFilePlus";
import IconHash from "@/icons/IconHash";
import IconLink from "@/icons/IconLink";
import IconList from "@/icons/IconList";
import IconPhone from "@/icons/IconPhone";
import IconText from "@/icons/IconText";
import React from "react";
import { Expression } from "@/components/RuleBuilder2/types";

export const nodeTypeDefs = {
  trigger: "Trigger",
  action: "Action"
};

export type NodeType = keyof typeof nodeTypeDefs;

export const triggerDefs = {
  incoming_message: "Incoming Message",
  conversation_opened: "Conversation Opened",
  conversation_resolved: "Conversation Resolved",
  visitor_opens_page: "Visitor Opens a Web Page",
  form_submitted: "Form Submitted",
  unresponsive_agent: "Unresponsive Agent",
  unresponsive_visitor: "Unresponsive Visitor",
  agent_timeout: "Agent Timeout",
  file_uploaded: "File Uploaded",
  file_downloaded: "File Downloaded",
  conversation_assigned: "Conversation Assigned To"
};

export type Trigger = keyof typeof triggerDefs;

export const premiumTriggerDefs = {
  appointment_scheduled: "Appointment Scheduled",
  service_ticket_created: "Service Ticket Created",
  visitor_returns: "Visitor Returns to the Site",
  recent_reply: "Recent Reply"
};

export const actions = {
  sendMessage: {
    name: "Send Message",
    description: "Send an automated message in the workflow",
    icon: SendIcon
  },
  condition: {
    name: "Condition",
    description: "Create branching paths in your workflows",
    icon: ConditionIcon
  },
  // assignTo: {
  //   name: "Assign to",
  //   description: "Route conversations to specific teams or agents",
  //   icon: AssignToIcon
  // },
  askQuestion: {
    name: "Ask Question",
    description: "Prompt the user with a specific type of question",
    icon: AskQuestionIcon
  },
  jumpTo: {
    name: "Jump to",
    description: "Redirects to a specific step within this workflow",
    icon: JumptoIcon
  },
  // ai: {
  //   name: "AI Chatbot",
  //   description: "Adds an AI chatbot to enhance the workflow",
  //   icon: AiIcon
  // },
  // timeDelay: {
  //   name: "Time Delay",
  //   description: "Introduce strategic pauses into your workflow",
  //   icon: TimeDelayIcon
  // },
  // httpRequest: {
  //   name: "HTTP Request",
  //   description: "Uses web requests to perform custom actions",
  //   icon: HttpIcon
  // },
  closeConversation: {
    name: "Close Conversation",
    description: "Manage conversation status within your workflow",
    icon: CloseConversationIcon
  }
  // addNote: {
  //   name: "Add Note",
  //   description: "Add an internal note to contacts or conversations",
  //   icon: NoteIcon
  // },
  // addTags: {
  //   name: "Add Tags",
  //   description: "Add tags to contacts or conversations",
  //   icon: TagIcon
  // },
  // sendSurvey: {
  //   name: "Send Survey",
  //   description: "Send survey",
  //   icon: ConditionIcon // reuses ConditionIcon
  // },
  // collectData: {
  //   name: "Collect Data",
  //   description: "Collect data",
  //   icon: CollectDataIcon
  // },
  // sendTranscript: {
  //   name: "Send Transcript",
  //   description: "Sends a copy of the conversation transcript to email",
  //   icon: SendTranscriptIcon
  // },
  // faq: {
  //   name: "FAQ",
  //   description: "FAQ",
  //   icon: ConditionIcon // reuses ConditionIcon
  // },
  // triggerWorkflow: {
  //   name: "Trigger Another Workflow",
  //   description: "Trigger the start of another workflow from this workflow",
  //   icon: TriggerWorkflowIcon
  // }
};

export type Action = keyof typeof actions;

export function getNodeIcon(node: WorkflowNode): SvgIcon {
  if (node.type === "action") {
    return actions[node.actionType].icon;
  }
  return PlayIcon;
}

export const questionDefs = {
  text: { label: "Text", icon: <IconText /> },
  number: { label: "Number", icon: <IconHash /> },
  url: { label: "URL", icon: <IconLink /> },
  phone: { label: "Phone", icon: <IconPhone /> },
  email: { label: "Email Address", icon: <IconEmail /> },
  multipleChoice: { label: "Multiple Choice", icon: <IconList /> }
  //file: { label: "File Upload", icon: <IconFilePlus /> }
};
export type Question = keyof typeof questionDefs;

const dayOfWeekKeys = Object.keys(dayOfWeekDefs) as DayOfWeek[];
export const dayOfWeekOptions: DayOfWeek[] = dayOfWeekKeys.map((value) => {
  return (dayOfWeekDefs[value].charAt(0).toUpperCase() +
    dayOfWeekDefs[value].slice(1)) as DayOfWeek;
});

export type NodeId = number;

export type WorkflowNode = {
  id: NodeId;
  type: NodeType;
  name?: string;
  child?: NodeId;
} & TriggerNode &
  ActionNode &
  SendMessageNode &
  JumpToNode &
  QuestionNode &
  ConditionNode;

export type TriggerNode = {
  trigger: Trigger;
  overrideRouting: boolean; //when this workflow is triggered, stop default routing
  channels: string[];
  triggerExpression: Expression; //parses to an Expression
};

export type ActionNode = {
  actionType: Action;
};

export type SendMessageNode = {
  text: string;
};

export type JumpToNode = {
  jumpToNodeId?: number;
};

export type QuestionNode = {
  questionType: Question;
  question: string;
  options?: NodeOption[]; //for multiple choice questions
  saveAsContactProp: boolean;
  saveAsConversationProp: boolean;
  saveAsWorkflowProp: boolean;
  propName: string;
  invalidReplyAction: "repeat" | "failBranch";
  invalidChild?: NodeId;
};

export interface NodeOption {
  childNodeId?: NodeId;
  label?: string;
}

export type ConditionNode = {
  description?: string;
  conditionOptions?: ConditionNodeOption[]; //for condition nodes
};

export interface ConditionNodeOption extends NodeOption {
  expression: Expression;
}

export interface NodeColors {
  bgColor: string;
  borderColor: string;
  optionColor: string;
}

export interface Workflow {
  id: number;
  name: string;
  createdBy: string;
  dateCreated: string;
  lastModified: string;
  triggerType: Trigger;
  enabled: boolean;
  nodes: Record<NodeId, WorkflowNode>;
}

export type WorkflowListItem = Omit<Workflow, "nodes">;
