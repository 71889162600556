import React from "react";
import { IconProps } from "./types";

function IconLink({ className = "stroke-slate-500", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      className={className}
      {...props}
    >
      <path
        d="M12.5001 22.6668H9.83341C6.15152 22.6668 3.16675 19.6821 3.16675 16.0002C3.16675 12.3183 6.15152 9.3335 9.83341 9.3335H12.5001M20.5001 22.6668H23.1667C26.8486 22.6668 29.8334 19.6821 29.8334 16.0002C29.8334 12.3183 26.8486 9.3335 23.1667 9.3335H20.5001M9.83341 16.0002L23.1667 16.0002"
        stroke="inherit"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconLink;
