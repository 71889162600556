import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { WorkflowListItem } from "./types";
import { toShortDate } from "@/helpers/dates";
import IconMoreHorizontalDots from "@/icons/IconMoreHorizontalDots";
import Toggle from "@velaro/velaro-shared/src/components/Toggle";
import IconCopy from "@velaro/velaro-shared/src/icons/IconCopy";
import IconEditPencil2 from "@/icons/IconEditPencil2";
import { IconProps } from "@/icons/types";
import IconTrash from "@velaro/velaro-shared/src/icons/IconTrash";
import { useNavigate } from "react-router-dom";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import {
  PrimaryBlueButton,
  SecondaryGrayButton
} from "@velaro/velaro-shared/src/components/Buttons/Buttons";

export function WorkflowItem({
  item,
  isLast,
  toggle,
  remove
}: {
  item: WorkflowListItem;
  isLast: boolean;
  toggle: () => void;
  remove: () => void;
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);
  const dateCreated = new Date(item.dateCreated || "");
  const lastModified = new Date(item.lastModified || "");
  const dotsRef = useRef<SVGSVGElement>(null);
  const className = isLast ? "" : "border-b border-slate-200";
  const navigate = useNavigate();

  useEffect(() => {
    function hideMenu(e: MouseEvent) {
      if (e.target != dotsRef.current) {
        setShowMenu(false);
      }
    }
    document.addEventListener("click", hideMenu);
    return () => {
      document.removeEventListener("click", hideMenu);
    };
  }, []);

  return (
    <tr className={`${className} h-12 hover:bg-slate-100`}>
      <td>
        <div className="flex items-center">
          <Checkbox />
        </div>
      </td>
      <td>{item.name}</td>
      <td>{item.createdBy}</td>
      <td>{toShortDate(lastModified)}</td>
      <td className="flex items-center h-12">
        <Toggle
          label={item.enabled ? "Published" : "Draft"}
          toggled={item.enabled}
          changeToggle={toggle}
        />
      </td>
      <td>
        <div className="relative flex justify-end">
          <div onClick={() => setShowMenu(true)} className="cursor-pointer">
            <IconMoreHorizontalDots ref={dotsRef} />
          </div>
          {showMenu && (
            <div className="absolute -bottom-36 z-50 w-36 p-2 bg-white rounded shadow border border-slate-200 flex-col justify-start items-start inline-flex text-sm text-slate-800">
              <MenuItem
                icon={IconEditPencil2}
                text={"Edit"}
                onClick={() => navigate(`${item.id}`)}
              />
              <MenuItem
                icon={IconCopy}
                text={"Duplicate"}
                onClick={() => setShowMenu(false)}
              />
              <MenuItem
                icon={IconTrash}
                text={"Delete"}
                onClick={() => {
                  setShowConfirmRemoveModal(true);
                  setShowMenu(false);
                }}
              />
            </div>
          )}
          <Modal show={showConfirmRemoveModal}>
            <Modal.Header
              title="Confirm Remove Workflow"
              onClose={() => setShowConfirmRemoveModal(false)}
            />
            <Modal.Body>
              <div className="m-4">
                Are you sure you want to remove this workflow?
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="mx-4 my-2 flex justify-between items-center">
                <SecondaryGrayButton
                  label={"Cancel"}
                  onClick={() => setShowConfirmRemoveModal(false)}
                />
                <PrimaryBlueButton
                  label={"Remove"}
                  onClick={() => {
                    setShowConfirmRemoveModal(false);
                    remove();
                  }}
                />
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </td>
    </tr>
  );
}

export function MenuItem(props: {
  onClick: () => void;
  icon: ({ ...props }: IconProps) => React.JSX.Element;
  text: string;
}) {
  return (
    <div
      onClick={props.onClick}
      className={`group self-stretch p-2 bg-white rounded-lg justify-start items-center gap-2 inline-flex hover:text-blue-500 hover:bg-slate-100 cursor-pointer`}
    >
      <props.icon className="group-hover:stroke-blue-500" />
      {props.text}
    </div>
  );
}

export function Checkbox(props: React.HTMLProps<HTMLInputElement>) {
  return (
    <input
      className={
        "w-4 h-4 bg-white rounded-3xl border border-slate-300 justify-center items-center gap-2 inline-flex"
      }
      {...props}
      type="checkbox"
    />
  );
}
