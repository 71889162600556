import * as React from "react";

interface Props {
  title: string;
  headerActions?: React.ReactNode;
  children: React.ReactNode;
}

export default function RouteHeader(props: Props) {
  return (
    <>
      <div className="pb-4 mb-4 flex items-center justify-between">
        <span className="text-lg font-bold text-gray-800">{props.title}</span>
        {props.headerActions && <span>{props.headerActions}</span>}
      </div>
      {props.children}
    </>
  );
}
