import { Option } from "@/types";
import { ChannelEditor } from "./ConditionEditors/ChannelEditor";
import { DomainEditor } from "./ConditionEditors/DomainEditor";
import { DayOfWeekEditor } from "./ConditionEditors/DayOfWeekEditor";
import { TimeOfDayEditor } from "./ConditionEditors/TimeOfDayEditor";
import { ConditionProps } from "./ConditionBlock";
import { DateEditor } from "./ConditionEditors/DateEditor";
import { UrlEditor } from "./ConditionEditors/UrlEditor";
import { AttributeEditor } from "./ConditionEditors/AttributeEditor";
import { TeamEditor } from "./ConditionEditors/TeamEditor";

export const comparatorOptions = {
  equals: "is",
  notEquals: "is not",
  contains: "contains",
  notContains: "doesn't contain",
  greaterThan: "is greater than",
  lessThan: "is less than",
  exists: "exists",
  notExists: "doesn't exist"
};

export type Comparator = keyof typeof comparatorOptions;

export const conditionDefs: Record<
  string,
  { label: string; editor: (props: ConditionProps) => JSX.Element }
> = {
  channel: { label: "Channel", editor: ChannelEditor },
  domain: { label: "Domain", editor: DomainEditor },
  url: { label: "Url", editor: UrlEditor },
  dayOfWeek: { label: "Day of Week", editor: DayOfWeekEditor },
  timeOfDay: { label: "Time of Day", editor: TimeOfDayEditor },
  date: { label: "Date", editor: DateEditor },
  attribute: { label: "Attribute", editor: AttributeEditor },
  team: { label: "Team", editor: TeamEditor }
  // agentStatus: { label: "Agent Status", editor: AgentStatusEditor },
  // conversationStatus: { label: "Conversation Status", editor: TimeOfDayEditor },
  // tag: { label: "Tag", editor: TimeOfDayEditor },
  // agentAssigned: { label: "Agent Assigned", editor: TimeOfDayEditor },
  // aiChatbot: { label: "AI Chatbot is", editor: TimeOfDayEditor },
  // lastOpened: { label: "Last Opened", editor: TimeOfDayEditor },
  // lastResolved: { label: "Last Resolved", editor: TimeOfDayEditor },
  // lastIncomingMessage: {
  //   label: "Last Incoming Message",
  //   editor: TimeOfDayEditor
  // },
  // lastOutgoingMessage: {
  //   label: "Last Outgoing Message",
  //   editor: TimeOfDayEditor
  // },
  // timeSinceLastIncomingMessage: {
  //   label: "Time Since Last Incoming Message",
  //   editor: TimeOfDayEditor
  // },
  // timeSinceLastOutgoingMessage: {
  //   label: "Time Since Last Outgoing Message",
  //   editor: TimeOfDayEditor
  // }
};

type ConditionType = keyof typeof conditionDefs;
export const conditionOptions: Option[] = Object.keys(conditionDefs).map(
  (key: string) => {
    return {
      label: conditionDefs[key as ConditionType].label,
      value: key
    };
  }
);

export type Expression = SerializedCondition[][];

export type SerializedCondition = {
  type: ConditionType;
  data: string;
};
