import React, { useEffect, useState } from "react";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import FileUpload from "../../FileStorage/FileUpload";
import useToastAlert from "@/hooks/useToastAlert";
import useApi from "@/hooks/useApi";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import { UploadedFile } from "../../FileStorage/types";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";

export default function AIChatbotSettingsTrainingTabFile() {
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(true);
  const toast = useToastAlert();
  const api = useApi();

  async function save() {
    setLoading(true);
    const formData = new FormData();

    for (const file of newFiles) {
      formData.append("files", file);
    }

    const result = await api.messaging.postFormData("uploadedFiles", formData);
    if (result.status !== 200) {
      setLoading(false);
      toast.displayToast(
        Severity.Error,
        "Error uploading files: " + (await result.text())
      );
      return;
    }

    setFiles([...files, ...(await result.json())]);
    setNewFiles([]);
    toast.displayToast(Severity.Success, "Files uploaded successfully");
    setLoading(false);
  }

  async function remove(file: UploadedFile) {
    await api.messaging.delete("uploadedFiles", { id: file.id });
    const clone = [...files];
    clone.splice(files.indexOf(file), 1);
    setFiles(clone);
  }

  useEffect(() => {
    async function fetchFiles() {
      const response = await api.messaging.get("uploadedFiles/list");
      setFiles(await response.json());
      setLoading(false);
    }

    fetchFiles();
  }, [api.messaging]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="mt-4">
      <FileUpload onAddFiles={setNewFiles} />

      <div className="flex justify-end mt-4">
        <PrimaryBlueButton
          label="Upload and Train"
          disabled={newFiles.length === 0}
          onClick={() => console.log("button clicked")}
        />
      </div>
    </div>
  );
}
