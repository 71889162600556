import IconFlag from "@/icons/IconFlag";
import IconPlus from "@/icons/IconPlus";
import React, { DragEventHandler, useEffect, useRef } from "react";
import { Action, NodeColors, NodeType, actions } from "../types";
import IconCopy from "@/icons/IconCopy";
import IconTrash2 from "@/icons/IconTrash2";
import { DraggingData } from "../WorkflowsEditor";
interface NodeProps {
  icon: React.ReactNode;
  title: string;
  text?: string;
  type: NodeType;
  action: Action;
  selected: boolean;
  name?: string;
  hasOptions: boolean;
  nodeColors: NodeColors;
  onSelect: () => void;
  onDelete: () => void;
  onCopy: () => void;
  onDragStart: DragEventHandler<HTMLDivElement>;
  onDragEnd: DragEventHandler<HTMLDivElement>;
}

export function Node({
  icon,
  title,
  text,
  type,
  action,
  selected,
  name,
  hasOptions,
  nodeColors,
  onSelect,
  onDelete,
  onCopy,
  onDragStart,
  onDragEnd
}: NodeProps) {
  const textColor = text ? "text-slate-500 font-bold" : "text-slate-300";
  let borderColor = nodeColors.borderColor;
  if (!selected) {
    borderColor = " border-slate-200 shadow";
  }

  return (
    <div className="relative">
      <div
        draggable={true}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onClick={onSelect}
        id="node"
        className="flex flex-col cursor-pointer relative"
      >
        <div
          className={`w-64 h-12 p-4 ${nodeColors.bgColor} rounded-tl-2xl rounded-tr-2xl flex-col justify-center items-center gap-2 inline-flex`}
        >
          <div className="self-stretch h-full justify-start items-center gap-1.5 inline-flex">
            <div className="w-6 h-6 relative">{icon}</div>
            <div className="text-white text-sm leading-tight overflow-hidden h-full">
              {name || title}
            </div>
          </div>
        </div>
        <div
          className={`w-64 h-14 p-2 bg-white rounded-bl-2xl rounded-br-2xl border ${borderColor} ${textColor} flex-col justify-center items-center inline-flex`}
        >
          <div
            className={`${textColor} self-stretch inline-flex overflow-hidden text-sm leading-tight h-min max-h-9`}
          >
            {text}
          </div>
        </div>
        {selected && (
          <div
            onMouseDown={(e) => e.stopPropagation()}
            className="absolute -left-7 top-1/2 -translate-y-1/2 cursor-grab"
          >
            <DragDots />
          </div>
        )}
      </div>
      {selected && (
        <NodeActions>
          {!hasOptions && (
            <div
              onClick={onCopy}
              className="w-10 h-10 p-2 bg-slate-50 rounded-lg shadow border border-slate-200 cursor-pointer"
            >
              <IconCopy />
            </div>
          )}
          <div
            onClick={onDelete}
            className="w-10 h-10 p-2 bg-slate-50 rounded-lg shadow border border-slate-200 cursor-pointer"
          >
            <IconTrash2 />
          </div>
        </NodeActions>
      )}
    </div>
  );
}

export function EndNode() {
  return (
    <div className="w-64 h-10 p-4 bg-slate-100 rounded-2xl border border-slate-200 justify-start items-center gap-2 flex">
      <IconFlag />
      <span className="text-slate-400 text-sm">End of Workflow</span>
    </div>
  );
}

export function Connector({
  selectedConnector,
  draggingData,
  onClick,
  onDragEnter,
  onDragLeave
}: {
  selectedConnector?: HTMLDivElement;
  draggingData: DraggingData | null;
  onClick: (el: HTMLDivElement) => void;
  onDragEnter: (el: HTMLDivElement) => void;
  onDragLeave: () => void;
}) {
  const ref = useRef<HTMLDivElement>(null);

  const focused = selectedConnector === ref.current;
  const borderColor = focused
    ? "border-cornflower-blue-500"
    : "border-slate-200";

  const textColor = focused ? "text-cornflower-blue-500" : "text-slate-300";
  const draggedOver = draggingData?.draggedOverConnector?.el === ref.current;
  const draggingColor = draggedOver ? "bg-cornflower-blue-100" : "bg-white";
  return (
    <div ref={ref} className="w-8 h-32 flex flex-col">
      <div className="relative -left-[2px] translate-x-1/2 flex-grow border-l-4 border-slate-300"></div>
      {draggingData && (
        <div
          onDragEnter={() => {
            if (!ref.current) {
              return;
            }
            onDragEnter(ref.current);
          }}
          onDragLeave={onDragLeave}
          className={`w-8 h-8 border-2 border-dashed rounded-full border-cornflower-blue-500 flex items-center justify-center shadow cursor-pointer ${draggingColor} relative`}
        ></div>
      )}
      {!draggingData && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            if (!ref.current) {
              return;
            }
            onClick(ref.current);
          }}
          className={`w-8 h-8 border-2 rounded-full ${borderColor} flex items-center justify-center shadow cursor-pointer bg-white relative `}
        >
          <IconPlus className={textColor} />
        </div>
      )}
      <div className="relative -left-[2px] translate-x-1/2 flex-grow border-l-4 border-slate-300"></div>
    </div>
  );
}

export function ActionMenu({
  onSelect
}: {
  onSelect: (action: Action) => void;
}) {
  const actionKeys = Object.keys(actions) as Action[];
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    const { current: el } = ref;

    function onScroll(e: WheelEvent) {
      e.stopPropagation();
    }
    el.addEventListener("wheel", onScroll);
    return () => {
      el.removeEventListener("wheel", onScroll);
    };
  }, []);

  function renderIcon(action: Action) {
    const ActionIcon = actions[action].icon;
    return (
      <ActionIcon className="text-slate-500 group-hover:text-cornflower-blue-300" />
    );
  }

  return (
    <div
      ref={ref}
      className="w-80 bg-white text-xs p-1 rounded-lg shadow cursor-default"
    >
      <div className="p-2 font-bold">Add Action</div>
      <div className="overflow-y-auto h-[250px] flex flex-col gap-2">
        {actionKeys.map((action, i) => (
          <div
            onClick={() => onSelect(action)}
            key={i}
            className="group pl-2 cursor-pointer hover:bg-cornflower-blue-50 hover:text-cornflower-blue-300 flex gap-1 items-center justify-start"
          >
            <>
              {renderIcon(action)}
              <span className="h-8 flex items-center">
                {actions[action].name}
              </span>
            </>
          </div>
        ))}
      </div>
    </div>
  );
}

function NodeActions({ children }: { children: React.ReactNode }) {
  return (
    <>
      <div className="absolute left-1 -bottom-12 justify-center items-center gap-2 inline-flex">
        {children}
      </div>
    </>
  );
}

function DragDots() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_2651_89045)">
        <path
          d="M8 5C8 5.26522 8.10536 5.51957 8.29289 5.70711C8.48043 5.89464 8.73478 6 9 6C9.26522 6 9.51957 5.89464 9.70711 5.70711C9.89464 5.51957 10 5.26522 10 5C10 4.73478 9.89464 4.48043 9.70711 4.29289C9.51957 4.10536 9.26522 4 9 4C8.73478 4 8.48043 4.10536 8.29289 4.29289C8.10536 4.48043 8 4.73478 8 5Z"
          stroke="#CBD5E1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 12C8 12.2652 8.10536 12.5196 8.29289 12.7071C8.48043 12.8946 8.73478 13 9 13C9.26522 13 9.51957 12.8946 9.70711 12.7071C9.89464 12.5196 10 12.2652 10 12C10 11.7348 9.89464 11.4804 9.70711 11.2929C9.51957 11.1054 9.26522 11 9 11C8.73478 11 8.48043 11.1054 8.29289 11.2929C8.10536 11.4804 8 11.7348 8 12Z"
          stroke="#CBD5E1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 19C8 19.2652 8.10536 19.5196 8.29289 19.7071C8.48043 19.8946 8.73478 20 9 20C9.26522 20 9.51957 19.8946 9.70711 19.7071C9.89464 19.5196 10 19.2652 10 19C10 18.7348 9.89464 18.4804 9.70711 18.2929C9.51957 18.1054 9.26522 18 9 18C8.73478 18 8.48043 18.1054 8.29289 18.2929C8.10536 18.4804 8 18.7348 8 19Z"
          stroke="#CBD5E1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 5C14 5.26522 14.1054 5.51957 14.2929 5.70711C14.4804 5.89464 14.7348 6 15 6C15.2652 6 15.5196 5.89464 15.7071 5.70711C15.8946 5.51957 16 5.26522 16 5C16 4.73478 15.8946 4.48043 15.7071 4.29289C15.5196 4.10536 15.2652 4 15 4C14.7348 4 14.4804 4.10536 14.2929 4.29289C14.1054 4.48043 14 4.73478 14 5Z"
          stroke="#CBD5E1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 12C14 12.2652 14.1054 12.5196 14.2929 12.7071C14.4804 12.8946 14.7348 13 15 13C15.2652 13 15.5196 12.8946 15.7071 12.7071C15.8946 12.5196 16 12.2652 16 12C16 11.7348 15.8946 11.4804 15.7071 11.2929C15.5196 11.1054 15.2652 11 15 11C14.7348 11 14.4804 11.1054 14.2929 11.2929C14.1054 11.4804 14 11.7348 14 12Z"
          stroke="#CBD5E1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 19C14 19.2652 14.1054 19.5196 14.2929 19.7071C14.4804 19.8946 14.7348 20 15 20C15.2652 20 15.5196 19.8946 15.7071 19.7071C15.8946 19.5196 16 19.2652 16 19C16 18.7348 15.8946 18.4804 15.7071 18.2929C15.5196 18.1054 15.2652 18 15 18C14.7348 18 14.4804 18.1054 14.2929 18.2929C14.1054 18.4804 14 18.7348 14 19Z"
          stroke="#CBD5E1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2651_89045">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
