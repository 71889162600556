import React, { useEffect, useState } from "react";
import CustomizableSelect from "@velaro/velaro-shared/src/components/inputs/CustomizableSelect";
import Toggle from "@velaro/velaro-shared/src/components/Toggle";
import AIChatbotSettingsRow from "./AIChatbotSettingsRow";
import AIChatbotSettingsRowToggle from "./AIChatbotSettingsRowToggle";
import AIChatbotSettingsTabs from "./AIChatbotSettingsTabs";
import useAIConfigs from "@/hooks/useAIConfigs";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import IconPlus from "@velaro/velaro-shared/src/icons/IconPlus";
import IconText from "@velaro/velaro-shared/src/icons/IconText";
import IconHash from "@velaro/velaro-shared/src/icons/IconHash";
import IconSearchKB from "@velaro/velaro-shared/src/icons/IconSearchKB";
import { useParams } from "react-router-dom";
import AddNewVariableModal from "./AddNewVariableModal";

export default function AIChatbotSettingsData() {
  const { id } = useParams();
  const { aiConfigurations, loading, update } = useAIConfigs();
  const [showModal, setShowModal] = useState(false);

  function handleVariableType(arg0: string) {
    console.log(arg0);
    setShowModal(true);
  }

  const variableTypes = [
    {
      label: "Add new variable",
      action: () => handleVariableType("new"),
      icon: <IconPlus />
    },
    {
      label: "Name",
      action: () => handleVariableType("name"),
      icon: <IconText />
    },
    {
      label: "Email",
      action: () => handleVariableType("email"),
      icon: <IconText />
    },
    {
      label: "Address",
      action: () => handleVariableType("address"),
      icon: <IconText />
    },
    {
      label: "Contact Number",
      action: () => handleVariableType("number"),
      icon: <IconHash />
    },
    {
      label: "Company Size",
      action: () => handleVariableType("size"),
      icon: <IconHash />
    },
    {
      label: "Role",
      action: () => handleVariableType("role"),
      icon: <IconText />
    }
  ];

  if (loading) return <LoadingSpinner />;

  return (
    <>
      <AIChatbotSettingsTabs selectedTab="Collect Data" aiConfigId={id!} />
      <div className="bg-white p-6 border-[1px] rounded-2xl shadow-sm w-[750px] self-center">
        <div className="border-b-[1px]">
          <div className="font-semibold text-lg pb-1">
            Collect Data (Optional)
          </div>
          <div className="text-slate-500 text-sm pb-2">
            Capture and store data from the ongoing conversation.
          </div>
        </div>
        <AIChatbotSettingsRow
          title="Variable"
          subtitle="Store data for captured properties such as name, phone number, email address or custom properties."
          input={<CustomizableSelect options={variableTypes} />}
        />
        <div className="py-3">
          <div className="font-semibold text-sm pb-1">{"Variable List"}</div>
          <div className="flex flex-col rounded-2xl border border-slate-200 justify-center items-center py-12">
            <div className="flex">
              <IconSearchKB />
            </div>
            <div className="flex font-semibold pt-2">No variables to show</div>
          </div>
        </div>
      </div>
      {showModal && <AddNewVariableModal close={() => setShowModal(false)} />}
    </>
  );
}
