import React, { useEffect, useState } from "react";
import AIChatbotSettingsTabs from "./AIChatbotSettingsTabs";
import useAIConfigs from "@/hooks/useAIConfigs";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import IconPlus from "@velaro/velaro-shared/src/icons/IconPlus";
import IconText from "@velaro/velaro-shared/src/icons/IconText";
import IconHash from "@velaro/velaro-shared/src/icons/IconHash";
import { useParams } from "react-router-dom";
import Preview from "../../WebMessenger/components/Preview";
import { LanguageConfigModel, StyleConfigModel } from "../../types";
import useStyleConfigs from "../../WebMessenger/hooks/useStyleConfigs";
import useLanguageConfigs from "../../WebMessenger/hooks/useLanguageConfigs";
import AI from "./AI";
import { AIConfiguration } from "../types";

function AIChatbotTestHeader({ text }: { text: string }) {
  return (
    <div className="text-slate-800 text-base font-medium leading-normal py-2 border-b-[1px]">
      {text}
    </div>
  );
}

function AIChatbotTestRow({ label, text }: { label: string; text: string }) {
  return (
    <div className="flex py-2">
      <div className="w-1/4 text-slate-500 text-sm font-medium font-['Rubik'] leading-tight">
        {label}
      </div>
      <div className="w-3/4 text-slate-800 text-sm font-normal font-['Rubik'] leading-tight">
        {text}
      </div>
    </div>
  );
}

export default function AIChatbotSettingsTest() {
  const { id } = useParams();
  const { aiConfigurations, loading, update } = useAIConfigs();
  const [showModal, setShowModal] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const {
    styleConfigs,
    setStyleConfigs,
    loading: loadingStyleConfig
  } = useStyleConfigs();
  const {
    languageConfigs,
    setLanguageConfigs,
    loading: loadingLanguageConfig
  } = useLanguageConfigs();
  const [selectedStyleConfigId, setSelectedStyleConfigId] = useState(0);
  const [selectedLanguageConfigId, setSelectedLanguageConfigId] = useState(0);
  const [workingStyleConfig, setWorkingStyleConfig] =
    useState<StyleConfigModel | null>(null);
  const [workingLanguageConfig, setWorkingLanguageConfig] =
    useState<LanguageConfigModel | null>(null);
  //const [previewType, setPreviewType] = React.useState("Landing");
  const [config, setConfig] = useState<AIConfiguration>();
  const [configIndex, setConfigIndex] = React.useState<number>();

  useEffect(() => {
    if (aiConfigurations.length > 0 && id) {
      const configIndex = aiConfigurations.findIndex(
        (config) => config.id === parseInt(id)
      );
      if (configIndex) {
        setConfig(aiConfigurations[configIndex]);
        setConfigIndex(configIndex);
      }
    }
  }, [aiConfigurations, id]);

  useEffect(() => {
    if (!loadingStyleConfig && selectedStyleConfigId == 0) {
      setSelectedStyleConfigId(styleConfigs[0]?.id);
      setWorkingStyleConfig(styleConfigs[0]);
    }
  }, [loadingStyleConfig, selectedStyleConfigId, styleConfigs]);

  useEffect(() => {
    if (!loadingLanguageConfig && selectedLanguageConfigId == 0) {
      setSelectedLanguageConfigId(languageConfigs[0]?.id);
      setWorkingLanguageConfig(languageConfigs[0]);
    }
  }, [languageConfigs, loadingLanguageConfig, selectedLanguageConfigId]);

  function handleVariableType(arg0: string) {
    console.log(arg0);
    setShowModal(true);
  }

  function handleQuestionChange(e: any) {
    if (e.target.value.length > 120) {
      return;
    }
    setQuestion(e.target.value);
  }

  function handleAnswerChange(e: any) {
    if (e.target.value.length > 500) {
      return;
    }
    setAnswer(e.target.value);
  }

  const variableTypes = [
    {
      label: "Add new variable",
      action: () => handleVariableType("new"),
      icon: <IconPlus />
    },
    {
      label: "Name",
      action: () => handleVariableType("name"),
      icon: <IconText />
    },
    {
      label: "Email",
      action: () => handleVariableType("email"),
      icon: <IconText />
    },
    {
      label: "Address",
      action: () => handleVariableType("address"),
      icon: <IconText />
    },
    {
      label: "Contact Number",
      action: () => handleVariableType("number"),
      icon: <IconHash />
    },
    {
      label: "Company Size",
      action: () => handleVariableType("size"),
      icon: <IconHash />
    },
    {
      label: "Role",
      action: () => handleVariableType("role"),
      icon: <IconText />
    }
  ];

  if (
    loadingLanguageConfig ||
    loadingStyleConfig ||
    !workingLanguageConfig ||
    !workingStyleConfig ||
    loading ||
    !config
  ) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <AIChatbotSettingsTabs selectedTab="Test" aiConfigId={id!} />
      <div className="bg-white p-6 border-[1px] rounded-2xl shadow-sm w-full self-center flex">
        <div className="w-1/2 border-r-[1px] overflow-auto px-6">
          <AIChatbotTestHeader text="Chatbot Settings Summary" />
          <AIChatbotTestRow label="Chatbot Name" text={config?.name} />
          <AIChatbotTestRow label="Instructions" text={config?.prompt} />
          <AIChatbotTestRow label="AI Model" text={config?.aiModel} />
          <AIChatbotTestRow label="Language" text={config?.language} />
          <AIChatbotTestRow label="Reply Length" text={config?.replyLength} />
          <AIChatbotTestRow
            label="Knowledge Restriction"
            text={config?.knowledgeRestriction?.toString()}
          />
          <AIChatbotTestRow
            label="Follow Up Questions"
            text={config?.followUp?.toString()}
          />
          <AIChatbotTestRow
            label="Rate Chatbot Response"
            text={config?.rateResponse?.toString()}
          />
          <AIChatbotTestRow
            label="Activity Check"
            text={config?.activityCheck?.toString()}
          />
          <AIChatbotTestHeader text="Training" />
          <AIChatbotTestRow label="File" text="3 Files" />
          <AIChatbotTestRow label="URL" text="0 Urls" />
          <AIChatbotTestRow label="Sitemap" text="1 Sitemap" />
          <AIChatbotTestRow label="Text" text="6 Texts" />
          <AIChatbotTestHeader text="Handoff" />
          <AIChatbotTestRow label="Conversation Escalation" text="ON" />
          <AIChatbotTestRow label="Routing Message" text="Talk to human" />
          <AIChatbotTestRow label="Assign To" text="Sales team" />
          <AIChatbotTestRow label="AI Summarize" text="ON" />
        </div>
        <div
          className="w-1/2 px-4 top-30 ml-auto mr-auto"
          style={{ width: 500 }}
        >
          <Preview
            type={"AI"}
            workingStyleConfig={workingStyleConfig!}
            workingLanguageConfig={workingLanguageConfig!}
            aiConfigId={id}
          />
        </div>
      </div>
    </>
  );
}
