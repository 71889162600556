import Modal from "@velaro/velaro-shared/src/components/Modal";
import React, { useEffect, useMemo, useState } from "react";
import rawEmbedScript from "./EmbedScript.html?raw";
import { Deployment } from "@/pages/Messaging/Deployments/Deployments";
import useApi from "@/hooks/useApi";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";

interface Props {
  show: boolean;
  onClose(): void;
}

export default function DeployModal(props: Props) {
  // todo: get the embed script location

  // const { tenant, tenantLoading } = useTenant();

  // if (tenantLoading) {
  //   return <LoadingSpinner />;
  // }

  const [deployments, setDeployments] = useState<Deployment[]>([]);
  const [selectedDeployment, setSelectedDeployment] =
    useState<Deployment | null>();
  const api = useApi();
  const toastAlert = useToastAlert();

  useEffect(() => {
    async function fetchDeployments() {
      const resp = await api.messaging.get(`Deployment`);

      if (!resp.ok) {
        toastAlert.displayToast(Severity.Error, await resp.json());
      }

      setDeployments(await resp.json());
    }

    fetchDeployments();
  }, [api.messaging, toastAlert]);

  const embedScript = useMemo(() => {
    if (!selectedDeployment) {
      return "";
    }
    console.log("selectedDeployment", selectedDeployment);
    return rawEmbedScript
      .replace("{{ siteId }}", selectedDeployment.siteId.toString())
      .replace("{{ deploymentId }}", selectedDeployment.deploymentId.toString())
      .replace(
        "{{ scriptSrc }}",
        `${import.meta.env.VITE_MESSAGING_CLIENT_URL!}/messenger/embed.js`
      );
  }, [selectedDeployment]);

  const linkUrl = useMemo(() => {
    if (!selectedDeployment) {
      return "";
    }

    return `${import.meta.env.VITE_MESSAGING_CLIENT_URL!}/chat.html?siteId=${
      selectedDeployment.siteId
    }&deploymentId=${selectedDeployment.deploymentId}`;
  }, [selectedDeployment]);

  return (
    <Modal show={props.show} maxWidth="max-w-2xl">
      <Modal.Body>
        <div className="p-4">
          <h3 className="mb-1">Deployment</h3>
          <select
            className="input w-full p-2 mb-2"
            value={selectedDeployment?.id}
            onChange={(e) => {
              const id = Number(e.currentTarget.value);
              setSelectedDeployment(
                deployments.find((x) => x.id === id) || null
              );
            }}
          >
            <option value="">Select Deployment</option>
            {deployments.map((d) => (
              <option key={d.id} value={d.id}>
                {d.displayName}
              </option>
            ))}
          </select>
          <h3 className="mb-1">Direct Link</h3>
          <div className="mb-3">
            <input
              defaultValue={linkUrl}
              className="block w-full border rounded p-2"
            />
          </div>
          <div className="flex">
            <h3 className="mb-1">Embed</h3>
            {/* This is a button that takes you to an embedded preview of the messenger - commenting for now, but we might want it back later */}
            {/* <a
              href={`/test_web_messenger.html?siteId=${props.deployment.siteId}&deploymentId=${props.deployment.id}`}
              target="_blank"
              className="ml-auto underline"
              rel="noreferrer"
            >
              Test
            </a> */}
          </div>
          <textarea
            className="input w-full h-96 font-mono text-sm"
            defaultValue={embedScript}
          ></textarea>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="p-4 text-right">
          <button className="btn-green" onClick={props.onClose}>
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
