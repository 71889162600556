import IconAgentTimeout from "@/icons/IconAgentTimeout";
import IconArrowSquareDownRight from "@/icons/IconArrowSquareDownRight";
import IconCalendar from "@/icons/IconCalendar";
import IconDownload from "@/icons/IconDownload";
import IconFileCheck from "@/icons/IconFileCheck";
import IconLink from "@/icons/IconLink";
import IconMessageChatSquare from "@/icons/IconMessageChatSquare";
import IconMessageCheckSquare from "@/icons/IconMessageCheckSquare";
import IconMessageNotificationSquare from "@/icons/IconMessageNotificationSquare";
import IconOutgoingMessageSquare from "@/icons/IconOutgoingMessageSquare";
import IconTicket from "@/icons/IconTicket";
import IconUnresponsiveAgentSquare from "@/icons/IconUnresponsiveAgentSquare";
import IconUnresponsiveVisitorSquare from "@/icons/IconUnresponsiveVisitorSquare";
import IconUpload from "@/icons/IconUpload";
import IconUsersPlus from "@/icons/IconUsersPlus";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import { IconProps } from "@velaro/velaro-shared/src/icons/types";
import * as React from "react";
import { PremiumTag } from "../../../components/PremiumTag";

interface Props {
  onClose: () => void;
}

const triggers = [
  {
    icon: IconMessageNotificationSquare,
    title: "Incoming Message",
    text: "When receiving a message from a visitor.",
    id: "incoming_message"
  },
  {
    icon: IconOutgoingMessageSquare,
    title: "Outgoing Message",
    text: "When sending a message to a visitor.",
    id: "outgoing_message"
  },
  {
    icon: IconMessageChatSquare,
    title: "Conversation Opened",
    text: "When starting a new conversation with the customer.",
    id: "conversation_opened"
  },
  {
    icon: IconMessageCheckSquare,
    title: "Conversation Resolved",
    text: "When a conversation is marked resolved or compelted.",
    id: "conversation_resolved"
  },
  {
    icon: IconUnresponsiveAgentSquare,
    title: "Unresponsive Agent",
    text: "When an agent hasn't responded within a set timeframe.",
    id: "unresponsive_agent"
  },
  {
    icon: IconUnresponsiveVisitorSquare,
    title: "Unresponsive Visitor",
    text: "When a visitor doesn't respond during a conversation.",
    id: "unresponsive_visitor"
  },
  {
    icon: IconLink,
    title: "Visitor Opens a Web Page",
    text: "When a visitor lands on a specific website page.",
    id: "visitor_opens_page"
  },
  {
    icon: IconFileCheck,
    title: "Form Submitted",
    text: "When a visitor submits a form.",
    id: "form_submitted"
  },
  {
    icon: IconUsersPlus,
    title: "Conversation Assigned To",
    text: "When assigning a conversation to an agent or team.",
    id: "conversation_assigned_to"
  },
  {
    icon: IconUpload,
    title: "File Uploaded",
    text: "When a file is uploaded from the conversation.",
    id: "file_uploaded"
  },
  {
    icon: IconDownload,
    title: "File Downloaded",
    text: "When a file is downloaded from the conversation.",
    id: "file_downloaded"
  },
  {
    icon: IconAgentTimeout,
    title: "Agent Timeout",
    text: "When the conversation exceeds the wait threshold.",
    id: "agent_timeout"
  },
  {
    icon: IconCalendar,
    title: "Appointment Scheduled",
    text: "When an appointment is scheduled in the system.",
    id: "appointment_scheduled",
    premium: true
  },
  {
    icon: IconTicket,
    title: "Service Ticket Created",
    text: "When a user reports an issue or requests support.",
    id: "service_ticket_created",
    premium: true
  },
  {
    icon: IconArrowSquareDownRight,
    title: "Visitor Returns to the Site",
    text: "When an existing user returns to the site.",
    id: "visitor_returns_to_site",
    premium: true
  }
];

interface Props {
  onClose: () => void;
  onSelect(triggerId: string): void;
}

export default function TriggerModal(props: Props) {
  return (
    <Modal maxWidth={"max-w-6xl"} show={true}>
      <Modal.Header title="Select a Trigger" onClose={props.onClose} />
      <Modal.Body>
        <div className="m-4">
          <span className="text-sm text-gray-500">
            Triggers are specific events or conditions that, once met,
            kick-start your workflow
          </span>
          <div className="flex flex-wrap gap-4">
            {triggers.map((trigger, index) => (
              <TriggerCard
                onClick={() => props.onSelect(trigger.id)}
                key={index}
                {...trigger}
              />
            ))}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function TriggerCard(props: {
  icon: ({ color, ...props }: IconProps) => React.JSX.Element;
  title: string;
  text: string;
  premium?: boolean;
  onClick?: () => void;
}) {
  return (
    <div
      onClick={props.onClick}
      className="group p-3 border border-gray-200 rounded-md w-64 hover:border-cornflower-blue-500 hover:shadow-sm hover:cursor-pointer"
    >
      <div className="flex justify-between items-center hover: text-cornflower-blue-500">
        <props.icon className="stroke-slate-500 fill-slate-500 group-hover:stroke-cornflower-blue-500 group-hover:fill-cornflower-blue-500" />
        {props.premium && <PremiumTag />}
      </div>
      <div>
        <div className="font-semibold py-2 group-hover:text-cornflower-blue-500">
          {props.title}
        </div>
        <div className="text-sm text-gray-500">{props.text}</div>
      </div>
    </div>
  );
}
