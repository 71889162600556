import * as React from "react";
import { useMemo } from "react";
import { WorkflowNode, actions, getNodeIcon } from "../types";
import { Header } from "./RightAsideHelpers";
import TriggerEditor from "./TriggerEditor";
import ActionEditorBase from "./ActionEditors/ActionEditorBase";

interface Props {
  node: WorkflowNode;
  onUpdate: (node: WorkflowNode) => void;
  onClose: () => void;
}

export default function WorkflowsRightAside({
  node,
  onUpdate,
  onClose
}: Props) {
  const headerTitle = useMemo(() => {
    if (node.type === "trigger") {
      return "Trigger";
    }

    if (node.type === "action") {
      return actions[node.actionType].name;
    }

    return "";
  }, [node]);

  const headerDescription = useMemo(() => {
    if (node.type === "trigger") {
      return "Define the starting point of your workflows";
    }

    if (node.type === "action") {
      return actions[node.actionType].description;
    }

    return "";
  }, [node]);

  function renderEditor() {
    switch (node.type) {
      case "trigger":
        return <TriggerEditor onUpdate={onUpdate} node={node} />;
      case "action":
        return <ActionEditorBase onUpdate={onUpdate} node={node} />;
      default:
        return "missing editor";
    }
  }

  const NodeIcon = getNodeIcon(node);
  return (
    <div className="cursor-default text-slate-800 w-[400px] shrink-0 border-l bg-white rounded-3xl -ml-[40px] z-20 right-aside">
      <div className="w-full h-full overflow-y-auto p-6">
        <Header
          icon={<NodeIcon className="text-slate-500" />}
          type={headerTitle}
          message={headerDescription}
          onClose={onClose}
        />
        {renderEditor()}
      </div>
    </div>
  );
}
