import useTeamListItems from "@/hooks/useTeamListItems";
import React from "react";

export default function TeamSelect(
  props: React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >
) {
  const { teams } = useTeamListItems();

  return (
    <>
      <label className="pr-2">Team</label>
      <select {...props}>
        {teams.map((team) => (
          <option key={team.id} value={team.id}>
            {team.name}
          </option>
        ))}
      </select>
    </>
  );
}
